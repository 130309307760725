import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, Divider } from "@mui/material";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
  const { window, loteSelec } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(85% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <Box sx={{ textAlign: "center", pt: 1 }}>
        <Button onClick={toggleDrawer(true)}>Open</Button>
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {loteSelec.nombre}
          </Typography>
        </StyledBox>
        <Card
          sx={{
            maxWidth: 400,
            borderRadius: 0,
            boxShadow: "none",
            // pointerEvents: "none",
            height: "100vh",
          }}
        >
          <div className="sidebar-header">
            <img src={require("../data/LOGO (1).png")} width={75} />
          </div>
          <div className="sidebar-separator" />
          <CardActionArea>
            <CardMedia
              component="img"
              height="250"
              src={require("../data/PORTADA FACEBOOK2.jpg")}
              alt="lotus"
            />
          </CardActionArea>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="success"
              sx={{
                marginTop: 18,
                pointerEvents: "all",
                bgcolor: "gray",
                "&:hover": {
                  backgroundColor: "darkgray",
                },
              }}
            >
              Contactar
            </Button>
          </div>
        </Card>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {loteSelec === undefined ? "lote" : loteSelec.nombre}
            </Typography>
            <Typography
              sx={{ marginLeft: 1 }}
              variant="body2"
              color="text.secondary"
            >
              direccion
            </Typography>
            <Divider sx={{ bgcolor: "black", margin: 1 }}></Divider>
            <Typography
              sx={{ marginLeft: 1 }}
              variant="body2"
              color="text.secondary"
            >
              Superficie: {loteSelec.superficie} m<sup>2</sup>
            </Typography>
            <Typography
              sx={{ marginLeft: 1 }}
              variant="body2"
              color="text.secondary"
            >
              Precio: 30.000.00
            </Typography>
          </CardContent>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
