import React from 'react';
import { Carousel } from 'react-bootstrap';
import img1 from "../data/lotus2.jpg";
import img2 from "../data/04 AX ZONA COMUNAL 1 (7680X4320).jpg";
import img3 from "../data/16_Entrada Toma 3 Opción 3 (1).jpg";

function Carrusel(props) {
  return (
    <>
      <Carousel interval={3000} data-bs-theme="dark">
        {/* Cambia 3000 a la cantidad de milisegundos que deseas para el intervalo */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt="First slide"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt="Second slide"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Carrusel;