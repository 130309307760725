import { useEffect, useState } from "react";
import Mapventas from "./MapaVentas";
import { fetchTerrenosVendidos } from "../services/ServiceMap";
import lotes from "../data/Plano v9.json";

const Mapa = () => {
  const [terrenosVendidos, setTerrenosVendidos] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const terrenosData = await fetchTerrenosVendidos(lotes);
        setTerrenosVendidos(terrenosData);
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    }

    fetchData();
  }, []); // <-- Aquí especificamos un arreglo vacío de dependencias

  return (
    <>
      {terrenosVendidos ? <Mapventas lotes={terrenosVendidos}></Mapventas> : null}
    </>
  );
};

export default Mapa;





