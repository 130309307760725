import React, { useState } from "react";
import "../css/sidebar.css";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, Divider } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import Carrusel from "./Carrusel";


const Sidebar = ({ loteSelec }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const abrirEnlace = () => {
    window.open('https://www.lotus-san-antonio-riobamba.info/contactenos', '_blank');
  };

  const renderSidebarContent = () => {


    if (!loteSelec) {
      return (
        <>
          <Typography gutterBottom variant="h5" component="div">
            Lote
          </Typography>

          <Divider sx={{ bgcolor: "black", margin: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Superficie: 0 m<sup>2</sup>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Servicios: 
            <ul>
            <li>Infraestructura soterrada</li>
    <li>Internet por fibra optica</li>
    <li>Areas verdes, canchas múltiples</li>
    <li>Vias  internas  de 12mt2 de ancho</li>
    <li>Aprobado por Municipio de Riobamba</li>
    <li>Financiamiento Directo</li>

  </ul>
          </Typography>

        </>
      );
    }

    return (
      <>
        <Typography gutterBottom variant="h5" component="div">
          {loteSelec.nombre}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {loteSelec.direccion}
        </Typography>
        <Divider sx={{ bgcolor: "black", margin: 1 }} />
        <Typography variant="body2" color="text.secondary">
          Superficie: {loteSelec.superficie} m<sup>2</sup>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Servicios: 
            <ul>
    <li>Infraestructura soterrada</li>
    <li>Internet por fibra optica</li>
    <li>Areas verdes, canchas múltiples</li>
    <li>Vias  internas  de 12mt2 de ancho</li>
    <li>Aprobado por Municipio de Riobamba</li>
    <li>Financiamiento Directo</li>

  </ul>
        </Typography>
      </>
    );
  };

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <Card
        sx={{
          maxWidth: 400,
          borderRadius: 0,
          boxShadow: "none",
          height: "100vh",
        }}
      >
        <div className="sidebar-header">
          <img src={require("../data/LOGO (1).png")} width={75} alt="Logo" />
        </div>
        <div className="sidebar-separator" />
        <CardActionArea>
          <Carrusel />
          <CardContent>{renderSidebarContent()}</CardContent>
        </CardActionArea>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
        variant="contained"
        color="success"
        style={{
          marginTop: '18px',
          pointerEvents: 'all',
          backgroundColor: 'gray',
        }}
        onClick={abrirEnlace}
      >
        Contactar
      </Button>
        </div>
      </Card>
      <div className={`sidebar-toggle ${collapsed ? "collapsed" : ""}`}>
        <button className="sidebar-toggle-button" onClick={toggleSidebar}>
          <svg
            fill="currentColor"
            className="material-design-icon__svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
