export async function fetchTerrenosVendidos() {

  const options = {
    method: 'GET',
   // headers: headers,
   // body: JSON.stringify({ query: { equal: { estado: "VENDIDO" } } }),
    mode: 'cors'
  };

  try {
    const response = await fetch("https://vps.lotus-san-antonio-riobamba.info/api/data",options);
    const data = await response.json();

   /* const updatedFeatures = lotes.features.map(feature => {
      const codPoligono = feature.properties.nombre;
      const matchingInfo = data.data.find(info => info.cod_terreno === codPoligono);
  
      if (matchingInfo) {
        return {

          ...feature,
          properties: {
            ...feature.properties,
            estado: matchingInfo.estado
          }
        };
      }
  
      return feature;
    });*/
  
    return data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;



  }
}

export function processGeojsonWithTerrenos(lotes, terrenosVendidos) {
  
}