import React, { useState, useRef, useEffect } from "react"; //import { Map, TileLayer, Marker, Popup, MapContainer, GeoJSON } from 'react-leaflet';
import ReactDOMServer from "react-dom/server";
import img_avalon from "../data/avalonpark.jpg";
import img_terranova from "../data/terranova.jpeg";

import "mapbox-gl/dist/mapbox-gl.css";
import "../css/react-leaflet.css";
//import lotes from "../data/lotesv6.json";
//import vial from "../data/vialv3.json";
import bbox from "@turf/bbox";
import Sidebar from "./SideBar";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Threebox from "threebox-plugin/src/Threebox";
import SwipeableEdgeDrawer from "./Swipeable";
import treee from "../data/calles30.glb";
import {
  fetchTerrenosVendidos,
  processGeojsonWithTerrenos,
} from "../services/ServiceMap";
import { Marker } from "mapbox-gl"; // Importa la clase Marker
import CustomIcon from "../data/LOGO_REDES-removebg-preview.png"; // Ruta a tu icono personalizado
import Carrusel from "./Carrusel";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGllZ28xOTkiLCJhIjoiY2xpNnF0djd4MHNjNDN0czZxb3FneWkyZCJ9.8Ix1jII58NDQU85wGrdzlw";

const Mapventas = ({ lotes }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-78.64762616458162);
  const [lat, setLat] = useState(-1.6506294661405074);
  const [zoom, setZoom] = useState(18);
  const [loteSelec, setLoteSelect] = useState([]);
  const [active, setActive] = useState(false);
  const [isSidebar, setIsSidebar] = useState(true); // Estado para determinar si se muestra el sidebar

  const handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;
    setIsSidebar(screenWidth > 768); // Cambia el estado dependiendo del ancho de la pantalla (768 es solo un ejemplo)
  };
  console.log(lotes)

  useEffect(() => {
    // Agrega un event listener al cambio de tamaño de la ventana
    window.addEventListener("resize", handleWindowSizeChange);

    // Limpia el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // initialize map only once
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/outdoors-v12",
      center: [lng, lat],
      zoom: zoom,
      pitch: 64.9,
      bearing: 172.5,
      antialias: true,

      //   onclick: onClick,
      //  interactiveLayerIds: ["sf-neighborhoods-fill"]
    });

    setActive(true);
  });

  if (active) {
    // console.log(map.current)
    map.current.on("click", "sf-neighborhoods-fill", (event) => {

      if (event.features[0] !== undefined) {
        setLoteSelect(event.features[0].properties);
        const popup2 = new mapboxgl.Popup({ closeButton: false, className:"popup-lotes", })
        

          .setLngLat(event.lngLat) // Establece las coordenadas del popup
          .setHTML(`
          <div >
            <h4>${event.features[0].properties.nombre}</h4>
            <h6>Superficie: ${event.features[0].properties.superficie} m&sup2</h6>
            <!-- Agrega cualquier otra información que desees mostrar -->
            </div>
          `)
          .addTo(map.current);
      }
    });
    map.current.on("load", () => {
      // Add a data source containing GeoJSON data.

      if (map.current.getSource("sf-neighborhoods")) return;

      map.current.addSource("sf-neighborhoods", {
        type: "geojson",
        data: lotes,
      });

      // Add a new layer to visualize the polygon.
      map.current.addLayer({
        id: "sf-neighborhoods-fill",
        source: "sf-neighborhoods",
        type: "fill",
        paint: {
          "fill-outline-color": "#0040c8",
          "fill-color": [
            "match",
            ["get", "estado"], // Reemplaza 'vendido' con el nombre del atributo que indica el estado
            "VENDIDO",
            "#FF0000",
            "RESERVADO",
            "#FFD320", // Color cuando vendido es "pagad
            "#00FF00", // Color cuando vendido es "no pagado"// Color por defecto si no coincide con ninguno de los valores anteriores
          ],
          "fill-opacity": 0.5,
        },
      });
      // Add a black outline around the polygon.
      map.current.addLayer({
        id: "sf-neighborhoods-outline",
        source: "sf-neighborhoods",
        type: "line",
        paint: {
          "line-width": 1,
          "line-color": "#777777",
          "line-opacity": 1,
        },
      });
      map.current.addLayer({
        id: "etiquetas-layer",
        type: "symbol",
        source: "sf-neighborhoods",
        layout: {
          "text-field": ["get", "nombre"], // Utiliza la propiedad 'nombre' para el texto de la etiqueta
          "text-size": 20,
          "text-offset": [0, 1],
          "text-allow-overlap": false, // Evita que las etiquetas se superpongan
          "text-ignore-placement": true,
        },
        paint: {
          "text-color": "black",
        },
        filter: [
          "all",
          ["==", ["geometry-type"], "Polygon"], // Filtra solo polígonos
          ["in", ["get", "nombre"], ""], // Filtra el polígono específico por su nombre
          [">", ["zoom"], 18],
        ], // Muestra la etiqueta solo a partir del nivel de zoom 10
      });

      map.current.moveLayer(
        "custom-threebox-model-layer",
        "sf-neighborhoods-fill"
      );
      map.current.moveLayer(
        "custom-threebox-model-layer",
        "sf-neighborhoods-outline"
      );
    });
    const mapCanvas = map.current.getCanvas();

    // Escucha eventos de mouse para controlar el cursor

    const tb = (window.tb = new Threebox(
      map.current,
      map.current.getCanvas().getContext("webgl"),
      {
        defaultLights: true,
      }
    ));

    map.current.on("style.load", () => {
      map.current.addLayer({
        id: "custom-threebox-model-layer",
        type: "custom",
        renderingMode: "3d",
        onAdd: function (map, mbxContext) {

          const scale = 1;
          window.tb = new Threebox(map, mbxContext, { defaultLights: true });
          const options = {
            obj: treee,
            type: "gltf",
            scale: { x: scale, y: scale, z: scale },
            units: "meters",
            rotation: { x: 90, y: 180, z: 0 },
          };
          tb.loadObj(options, (model) => {
            model.setCoords([-78.649457, -1.652695]); //
            model.setRotation({ x: 0, y: -0.2, z: 0 });
            tb.add(model);
          });
        },

        render: function (gl, matrix) {
          tb.update();
        },
      });
    });

        map.current.on("load", () => {
   
          
      const popupContent = `


      <div class="popup"  >





      <img src=${img_avalon} alt="Urbaniación lotus" style="width: 300px; " />
        </div>

        <div>
        <a href="https://www.lotus-san-antonio-riobamba.info/avalon-park1" target="_blank"    style="  text-decoration: none;
        color: black;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;

        margin:0px;">Ver más información</a>
      </div>

    `;

    const popupContent2 = `


    <div class="popup"  >


    <img src=${img_terranova} alt="Urbaniación lotus" style="width: 300px; " />
      </div>

      <div>
      <a href="https://www.lotus-san-antonio-riobamba.info/terranova1" target="_blank"    style="  text-decoration: none;
      color: black;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;

      margin:0px;">Ver más información</a>
    </div>

  `;
 
    const markerElement = document.createElement("div");

    const el = document.createElement('div');
    el.className = 'marker';
    el.textContent = "AVALON    PARK";

    el.style.zIndex = 1000;

    const markerElement2 = document.createElement("div");

    const el2 = document.createElement('div');
    el2.className = 'marker2';
    el2.textContent = "TERRANOVA";

    el2.style.zIndex = 1000;

      // Agregar un marcador
      const marker = new  Marker(el)

        .setLngLat([-78.645550034518, -1.6490733752606268]) // Coordenadas del marcador
        .setPopup(new mapboxgl.Popup({closeButton: false, className:"popup-marker"}).setHTML(popupContent)) // Configurar el "popup"

        .addTo(map.current)

      const marker2 = new  Marker(el2)

        .setLngLat([-78.64777420796405, -1.653204062291108]) // Coordenadas del marcador
        .setPopup(new mapboxgl.Popup({closeButton: false, className:"popup-marker"}).setHTML(popupContent2))// Configurar el "popup"

        .addTo(map.current)  
    });



  } else {
  } // wait for map to initialize

  return (
    <div>
      {isSidebar ? (
        <Sidebar loteSelec={loteSelec} />
      ) : (
        <Sidebar loteSelec={loteSelec} /> // Renderiza la versión swipeable si el estado es falso
      )}

      <div ref={mapContainer} className="map-container" />
    </div>
  );
};
export default Mapventas;
