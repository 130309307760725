import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Mapa from './components/Mapa';
import './App.css';
import Map from './components/MapaVentas';
import Carrusel from './components/Carrusel';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path='/mapa' element={<Mapa />} />
    <Route path='/' element={<Mapa />} />
    <Route path='/carusel' element={<Carrusel/>} />
    </Routes>
    
    </BrowserRouter>
  );
}

export default App;
